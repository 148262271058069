import React from 'react'
import { Grid, GridItem } from '@which/seatbelt'

import type { LatestNews as LatestNewsType } from '../../../../generated/frontend'
import { ArticleListingHeader } from '../../../../shared/components/ArticleListingHeader/ArticleListingHeader'
import { Breadcrumb } from '../../../../shared/components/Breadcrumb/Breadcrumb'
import { LatestNewsCards } from '../../../../shared/components/LatestNewsCards/LatestNewsCards'
import { LocalFooter } from '../../../../shared/components/LocalFooter/LocalFooter'
import { ToolCTA } from '../../../../shared/components/ToolCTA/ToolCTA'
import type { ArticleListingReviewsPageData } from '../../article-listing-types'
import styles from './ArticleListingReviewsPage.module.scss'
import { HorizontalArticleCards } from './components/HorizontalArticleCards'

export const getReviewsListingComponents = (data: ArticleListingReviewsPageData) => {
  const {
    reviewsArticles: { featuredArticles, nonFeaturedArticles },
    header,
    moneyLocalNavigation,
    breadcrumb,
    latestNews,
    articleListingOptionalData,
  } = data
  const count = featuredArticles?.length + nonFeaturedArticles?.length || 0
  return {
    ArticleListingHeader: () => (
      <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
        <ArticleListingHeader
          articleTypeDescriptor="advice guides"
          className={styles.header}
          {...header}
          count={count}
        />
      </GridItem>
    ),
    Breadcrumb: () => <Breadcrumb localNavProps={moneyLocalNavigation} {...breadcrumb} />,
    FeaturedArticles: () => (
      <Grid className="w-page-wrapper">
        <GridItem span={{ large: 10 }} columnStart={{ large: 2 }}>
          <HorizontalArticleCards className={styles.articles} articles={featuredArticles} />
        </GridItem>
      </Grid>
    ),
    NonFeaturedArticles: () => (
      <GridItem span={{ large: 10 }} columnStart={{ large: 2 }}>
        <HorizontalArticleCards articles={nonFeaturedArticles} />
      </GridItem>
    ),
    LatestNews: () => (
      <GridItem span={{ medium: 12, large: 12 }}>
        <LatestNewsCards
          name={latestNews?.name}
          articles={latestNews?.articles as LatestNewsType['articles']}
          viewAllData={{ text: 'View all news', href: '/news' }}
        />
      </GridItem>
    ),
    MoneyToolCTA: () =>
      articleListingOptionalData?.components?.toolCTA ? (
        <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
          <ToolCTA {...articleListingOptionalData?.components?.toolCTA}></ToolCTA>
        </GridItem>
      ) : (
        <></>
      ),
    LocalFooter: articleListingOptionalData?.components?.localFooter
      ? () => (
          <GridItem className={styles.localFooter} span={{ medium: 12, large: 12 }}>
            <LocalFooter data={articleListingOptionalData.components.localFooter}></LocalFooter>
          </GridItem>
        )
      : () => <></>,
  }
}
