import { useBackStack } from '../../../shared/BrowserBackStackProvider'
import { getDataLayerScripts } from '../../../shared/data-layer'
import { getDFPScripts } from '../../../shared/dfp'
import { useFullUrl } from '../../../shared/hooks/useFullUrl'
import type { PageInfo } from '../../../shared/types/PageInfo'
import { usePageProps } from '../../../shared/usePageProps'
import { getPreviousUrl } from '../../../shared/utils/getPreviousUrl/getPreviousUrl'
import { removeFalsy } from '../../../shared/utils/remove-falsy'
import { removeUrlQueryStringAndFragment } from '../../../shared/utils/remove-url-query-string-and-fragment'
import type {
  ArticleListingTemplateName,
  ArticleListingVariantTypes,
  NewsListingFilteredTagNames,
} from '../article-listing-types'
import { getNewsAuthorListingDataLayer } from '../article-listing-variants/news-author-listing'
import { getNewsCategoryListingDataLayer } from '../article-listing-variants/news-category-listing'
import { getNewsTagListingDataLayer } from '../article-listing-variants/news-tag-listing'
import { useArticleListingPageDataLayer } from './useArticleListingPageDataLayer'

export const useArticleListingPageDataLayerItems = () => {
  const { getPageDatalayer } = useArticleListingPageDataLayer()
  const { template } = usePageProps()
  const { getFullUrl } = useFullUrl()
  const backStack = useBackStack()
  const previousUrl = getPreviousUrl({ backStack: backStack })

  return {
    getDataLayerItems(
      meta: ArticleListingVariantTypes['meta'],
      filteredNewsListingTagNames: NewsListingFilteredTagNames
    ) {
      const { dataLayer = [], taxonomyHierarchy } = meta || {}
      const pageInfo: PageInfo = {
        wcdPageUrl: removeUrlQueryStringAndFragment(getFullUrl()),
        vertical: taxonomyHierarchy?.vertical.slug,
        sub_vertical: taxonomyHierarchy?.subVertical?.slug,
        super_category: taxonomyHierarchy?.superCategory?.slug,
        category: taxonomyHierarchy?.category?.slug,
        ...filteredNewsListingTagNames,
        ...(previousUrl && { previousWcdPageUrl: previousUrl }),
      }
      const mappedPageInfo = removeFalsy(getPageDatalayer(pageInfo))
      const pageInfoDfp = getPageInfoForDfp(mappedPageInfo, template as ArticleListingTemplateName)

      return [
        ...getDataLayerScripts([...dataLayer, mappedPageInfo]),
        ...getDFPScripts(dataLayer, pageInfoDfp),
      ]
    },
  }
}

///////// IMPLEMENTATION /////////

const getPageInfoForDfp = (pageInfo: PageInfo, pageTemplate: ArticleListingTemplateName) => {
  switch (pageTemplate) {
    case 'News Category Listing':
      return getNewsCategoryListingDataLayer(pageInfo)
    case 'News Author Listing':
      return getNewsAuthorListingDataLayer(pageInfo)
    case 'News Tag Listing':
      return getNewsTagListingDataLayer(pageInfo)
    default:
      return pageInfo
  }
}
