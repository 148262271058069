import React from 'react'

import { Breadcrumb } from '../../../shared/components/Breadcrumb'
import type { ArticleListingVariantTypes } from '../article-listing-types'

export const getComponents = (data: ArticleListingVariantTypes, rest = {}) => {
  const { breadcrumb } = data

  return {
    Breadcrumb: () => <Breadcrumb {...breadcrumb} />,
    ...rest,
  }
}
