import React from 'react'
import { GridItem } from '@which/seatbelt'
import { LetterIcon } from '@which/seatbelt/src/components/Icons/Miscellaneous/Letter'
import { RegulationIcon } from '@which/seatbelt/src/components/Icons/Miscellaneous/Regulation'

import classnames from 'classnames'

import type { Callout as CalloutType } from '../../../../generated/frontend'
import { ArticleListingHeader } from '../../../../shared/components/ArticleListingHeader/ArticleListingHeader'
import { Breadcrumb } from '../../../../shared/components/Breadcrumb/Breadcrumb'
import { Callout } from '../../../../shared/components/Callout/Callout'
import { CategoryArticlesList } from '../../../../shared/components/CategoryArticlesList/CategoryArticlesList'
import { LocalFooter } from '../../../../shared/components/LocalFooter/LocalFooter'
import { TaxonomyConversionBanner } from '../../../../shared/components/TaxonomyConversionBanner/TaxonomyConversionBanner'
import type { ArticleListingCrPageData } from '../../article-listing-types'
import styles from './ArticleListingCRPage.module.scss'
import { HorizontalArticleCards } from './components/HorizontalArticleCards'

export const getCRListingComponents = (data: ArticleListingCrPageData) => {
  const {
    breadcrumb,
    header,
    consumerRightsFooter,
    consumerRightsNavigation,
    consumerRightsArticles: {
      featuredArticles,
      nonFeaturedArticles,
      letterArticles,
      regulationArticles,
    },
    taxonomyConversionBanner,
    callout,
  } = data

  return {
    CategoryPromo: () => <div>@TODO add CR Promo</div>,
    CRFeaturedArticles: () => (
      <GridItem span={{ large: 10 }} columnStart={{ large: 2 }}>
        <HorizontalArticleCards
          articles={featuredArticles}
          className={classnames({
            [styles.marginBottom]: !Boolean(nonFeaturedArticles.length),
          })}
        />
      </GridItem>
    ),
    CRNonFeaturedArticles: () => (
      <GridItem span={{ large: 10 }} columnStart={{ large: 2 }}>
        <HorizontalArticleCards articles={nonFeaturedArticles} className={styles.nonFeatured} />
      </GridItem>
    ),
    CategoryLettersList: () => (
      <CategoryArticlesList icon={LetterIcon} label="Free download" {...letterArticles} />
    ),
    CategoryRegulationsList: () => (
      <CategoryArticlesList icon={RegulationIcon} {...regulationArticles} />
    ),
    ConsumerRightsFooter: () => (
      <GridItem>
        <LocalFooter data={consumerRightsFooter} />
      </GridItem>
    ),
    Breadcrumb: () => <Breadcrumb localNavProps={consumerRightsNavigation} {...breadcrumb} />,
    TaxonomyConversionBanner: () => <TaxonomyConversionBanner {...taxonomyConversionBanner} />,
    Callout: () => (
      <GridItem span={{ large: 10 }} columnStart={{ large: 2 }}>
        <Callout
          title={callout?.title}
          byline={callout?.byline}
          description={callout?.description}
          items={callout?.items as CalloutType['items']}
          imageFormat={callout?.imageFormat ?? 'square'}
        />
      </GridItem>
    ),
    ArticleListingHeader: () => (
      <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
        <ArticleListingHeader {...header} />
      </GridItem>
    ),
  }
}
