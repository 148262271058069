import type { DataLayerInfo, PageInfo } from '../../../../shared/types'

export const getNewsAuthorListingDataLayer = (pageInfo: PageInfo) => {
  const authorDataLayer: DataLayerInfo = {
    ...pageInfo,
    content_type: 'news',
    pageType: 'news-author',
  }

  delete authorDataLayer.vertical
  delete authorDataLayer.sub_vertical

  return authorDataLayer
}
