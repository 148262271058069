import type { Callout } from '../../../generated/frontend'
import { useBaseMetaTags } from '../../../shared/hooks/useBaseMetaTags'
import { usePageProps } from '../../../shared/usePageProps'
import type {
  ArticleListingVariantTypes,
  NewsListingFilteredTagNames,
} from '../article-listing-types'
import { useArticleListingPageDataLayerItems } from './useArticleListingPageDataLayerItems'

export const useArticleListingMetaTags = () => {
  const { getBaseMetaTags } = useBaseMetaTags()
  const { getDataLayerItems } = useArticleListingPageDataLayerItems()
  const { context } = usePageProps()

  return {
    getMetaTags: ({ meta, callout, tags, author }: Meta) => {
      // TODO: come back to this - CDD-2156
      const imageUrl =
        (callout && callout.items.length !== 0 && callout.items[0]?.image?.src) || undefined

      return [
        ...getBaseMetaTags({ meta, imageUrl, context }),
        ...getDataLayerItems(meta, { tags, author }),
      ]
    },
  }
}

///////// IMPLEMENTATION /////////

type Meta = NewsListingFilteredTagNames & {
  meta: ArticleListingVariantTypes['meta']
  callout?: Callout
}
