import type { DataLayerInfo, PageInfo } from '../../../../shared/types'

export const getNewsTagListingDataLayer = (pageInfo: PageInfo) => {
  const tagDataLayer: DataLayerInfo = {
    ...pageInfo,
    content_type: 'news',
    pageType: 'news-tag',
  }

  delete tagDataLayer.vertical
  delete tagDataLayer.sub_vertical

  return tagDataLayer
}
