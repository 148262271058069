import type { DataLayerInfo, PageInfo } from '../../../../shared/types'

export const getReviewsListingDataLayer = (pageInfo: PageInfo) => {
  const reviewsDataLayer: DataLayerInfo = {
    ...pageInfo,
    content_type: 'advice-listing',
    pageType: 'advice-listing',
  }

  return reviewsDataLayer
}
