import type { FunctionComponent } from 'react'
import React from 'react'
import { ArticleCard } from '@which/seatbelt'

import type { ArticleListingReviewsPageData } from '../../../../article-listing-types'
import styles from './HorizontalArticleCards.module.scss'

export const HorizontalArticleCards: FunctionComponent<Props> = ({ articles = [], className }) => {
  if (!articles.length) {
    return null
  }

  return (
    <div data-testid="horizontal-article-cards" className={className}>
      {articles.map(({ href, image = '', strapline, title, multipageArticles, date, glideId }) => {
        const altProp = multipageArticles.length
          ? {
              linksListExpandableData: {
                items: multipageArticles,
                button: {
                  text: `In this guide (${multipageArticles.length} ${
                    multipageArticles.length > 1 ? 'articles' : 'article'
                  })`,
                  ariaLabel: `View more${
                    multipageArticles.length > 1 ? ' articles' : ''
                  } in this guide${title && ` - ${title}`}`,
                },
                contentHtmlId: `${glideId}-links-list`,
              },
            }
          : { date }

        return (
          <ArticleCard
            className={styles.reviewsArticleListingHorizontalCard}
            key={href}
            href={href}
            strapline={strapline}
            title={title}
            image={image}
            orientation={'horizontal'}
            imageAlt={title}
            {...altProp}
          />
        )
      })}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type FeaturedArticle = ArticleListingReviewsPageData['reviewsArticles']['featuredArticles']
type NonFeaturedArticle = ArticleListingReviewsPageData['reviewsArticles']['nonFeaturedArticles']

type Props = {
  articles: FeaturedArticle | NonFeaturedArticle
  className?: string
}
