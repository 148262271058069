import type { DataLayerInfo, PageInfo } from '../../../../shared/types'

export const getNewsCategoryListingDataLayer = (pageInfo: PageInfo) => {
  const newsDataLayer: DataLayerInfo = {
    ...pageInfo,
    vertical: pageInfo.sub_vertical || pageInfo.vertical,
    sub_vertical: pageInfo.category,
    content_type: 'news',
    pageType: 'news-sub-vertical',
  }

  delete newsDataLayer.category

  return newsDataLayer
}
