import { assertNever } from '@which/shared'

import type { PageInfo } from '../../../shared/types'
import { usePageProps } from '../../../shared/usePageProps'
import type { ArticleListingTemplateName } from '../article-listing-types'
import { getCrListingDataLayer } from '../article-listing-variants/cr-listing'
import { getNewsAuthorListingDataLayer } from '../article-listing-variants/news-author-listing'
import { getNewsCategoryListingDataLayer } from '../article-listing-variants/news-category-listing'
import { getNewsTagListingDataLayer } from '../article-listing-variants/news-tag-listing'
import { getReviewsListingDataLayer } from '../article-listing-variants/reviews-listing'

export const useArticleListingPageDataLayer = () => {
  const { template } = usePageProps()
  const articleListingTemplate = template as ArticleListingTemplateName

  return {
    getPageDatalayer(pageInfo: PageInfo) {
      switch (articleListingTemplate) {
        case 'CR Subtopic':
          return getCrListingDataLayer(pageInfo)
        case 'Reviews Article Listing':
          return getReviewsListingDataLayer(pageInfo)
        case 'News Category Listing':
          return getNewsCategoryListingDataLayer(pageInfo)
        case 'News Author Listing':
          return getNewsAuthorListingDataLayer(pageInfo)
        case 'News Tag Listing':
          return getNewsTagListingDataLayer(pageInfo)
        default:
          assertNever(articleListingTemplate)
      }
    },
  }
}
