import React from 'react'
import type { PageContext } from '@which/glide-ts-types'
import { GridItem } from '@which/seatbelt'

import { useArticleListingAuthorPageLazyQuery } from '../../../../generated/frontend'
import type { PageParams } from '../../../../routes'
import { Breadcrumb } from '../../../../shared/components/Breadcrumb/Breadcrumb'
import { FetchMore } from '../../../../shared/components/FetchMore'
import { GroupedArticles } from '../../../../shared/components/GroupedArticles/GroupedArticles'
import { ArticleListingAuthorHeader } from './components/ArticleListingAuthorHeader'

export const getNewsAuthorListingComponents = (data, pageMeta: PageMeta) => {
  const {
    newsAuthorArticles: { pagedArticles },
    newsAuthorHeader,
    breadcrumb,
  } = data

  // @TODO: Breadcrumb and Meta need to be done in data provider
  // No root taxonomy so needs to be looked at under another ticket
  // Once fixed will get added via hooks/base-components.tsx (ie: can be removed from this file)
  return {
    Breadcrumb: () => <Breadcrumb {...breadcrumb} />,
    ArticleListingHeader: () => (
      <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
        <ArticleListingAuthorHeader author={newsAuthorHeader.author} count={pagedArticles.total} />
      </GridItem>
    ),
    PagedArticles: () => (
      <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
        <FetchMore
          initialData={pagedArticles}
          lazyFetch={useArticleListingAuthorPageLazyQuery}
          lazyFetchVars={pageMeta}
          dataTarget="articleListingPage.newsAuthorArticles.pagedArticles"
          dataKey="articles"
        >
          <GroupedArticles />
        </FetchMore>
      </GridItem>
    ),
  }
}

///////// IMPLEMENTATION /////////

type PageMeta = Partial<PageParams> & {
  url?: string
  context?: PageContext | string
}
