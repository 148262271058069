import type { DataLayerInfo, PageInfo } from '../../../../shared/types'

export const getCrListingDataLayer = (pageInfo: PageInfo) => {
  const crListingDataLayer: DataLayerInfo = {
    ...pageInfo,
    topicName: pageInfo.sub_vertical,
    subtopicName: pageInfo.category,
    content_type: 'subtopic',
  }

  delete crListingDataLayer.sub_vertical
  delete crListingDataLayer.category

  return crListingDataLayer
}
