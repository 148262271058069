import React from 'react'
import type { PageContext } from '@which/glide-ts-types'
import { GridItem } from '@which/seatbelt'

import { useArticleListingTagPageLazyQuery } from '../../../../generated/frontend'
import type { PageParams } from '../../../../routes'
import { ArticleListingHeader } from '../../../../shared/components/ArticleListingHeader/ArticleListingHeader'
import { Breadcrumb } from '../../../../shared/components/Breadcrumb/Breadcrumb'
import { FetchMore } from '../../../../shared/components/FetchMore'
import { GroupedArticles } from '../../../../shared/components/GroupedArticles/GroupedArticles'
import type { ArticleListingTagPageData } from '../../article-listing-types'
import styles from '../../ArticleListingPage.module.scss'

export const getNewsTagListingComponents = (
  data: ArticleListingTagPageData,
  pageMeta: PageMeta
) => {
  const {
    newsTagArticles: { pagedArticles },
    newsTagHeader,
    breadcrumb,
  } = data

  // @TODO: Breadcrumb and Meta need to be done in data provider
  // No root taxonomy so needs to be looked at under another ticket
  // Once fixed will get added via hooks/base-components.tsx (ie: can be removed from this file)
  return {
    Breadcrumb: () => <Breadcrumb {...breadcrumb} />,
    ArticleListingHeader: () => (
      <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
        <ArticleListingHeader
          name={newsTagHeader.name}
          articleTypeDescriptor="news"
          count={pagedArticles.total}
          className={styles.articleListingHeader}
          description={newsTagHeader.description}
        />
      </GridItem>
    ),
    PagedArticles: () => (
      <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
        <FetchMore
          initialData={pagedArticles}
          lazyFetch={useArticleListingTagPageLazyQuery}
          lazyFetchVars={pageMeta}
          dataTarget="articleListingPage.newsTagArticles.pagedArticles"
          dataKey="articles"
        >
          <GroupedArticles />
        </FetchMore>
      </GridItem>
    ),
  }
}

///////// IMPLEMENTATION /////////

type PageMeta = Partial<PageParams> & {
  url?: string
  context?: PageContext | string
}
