import React from 'react'
import type { PageContext } from '@which/glide-ts-types'
import { GridItem } from '@which/seatbelt'

import { useArticleListingNewsPageLazyQuery } from '../../../../generated/frontend'
import type { PageParams } from '../../../../routes'
import { ArticleListingHeader } from '../../../../shared/components/ArticleListingHeader/ArticleListingHeader'
import { Breadcrumb } from '../../../../shared/components/Breadcrumb/Breadcrumb'
import { CategorySelection } from '../../../../shared/components/CategorySelection/CategorySelection'
import { FetchMore } from '../../../../shared/components/FetchMore'
import { GroupedArticles } from '../../../../shared/components/GroupedArticles/GroupedArticles'
import type { ArticleListingNewsPageData } from '../../article-listing-types'
import styles from '../../ArticleListingPage.module.scss'

export const getNewsCategoryListingComponents = (
  data: ArticleListingNewsPageData,
  pageMeta: PageMeta
) => {
  const {
    meta,
    breadcrumb,
    header,
    newsArticles: { pagedArticles },
    taxonomyHierarchyWithCounts,
  } = data
  return {
    Breadcrumb: () => <Breadcrumb {...{ ...breadcrumb, pageTitle: meta?.title }} />,
    ArticleListingHeader: () => (
      <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
        <ArticleListingHeader
          name={header.name}
          description={header.description}
          count={pagedArticles.total}
          className={styles.articleListingHeader}
          articleTypeDescriptor="news"
        />
      </GridItem>
    ),
    PagedArticles: () => {
      return (
        <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
          <FetchMore
            initialData={pagedArticles}
            lazyFetch={useArticleListingNewsPageLazyQuery}
            lazyFetchVars={pageMeta}
            dataTarget="articleListingPage.newsArticles.pagedArticles"
            dataKey="articles"
          >
            <GroupedArticles />
          </FetchMore>
        </GridItem>
      )
    },
    CategorySelection: () => (
      <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
        <CategorySelection taxonomyHierarchyWithCounts={taxonomyHierarchyWithCounts} />
      </GridItem>
    ),
  }
}

///////// IMPLEMENTATION /////////

type PageMeta = Partial<PageParams> & {
  url?: string
  context?: PageContext | string
}
