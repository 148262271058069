import type { FunctionComponent } from 'react'
import React from 'react'
import { ArticleCard } from '@which/seatbelt'

import classnames from 'classnames'

import type { ArticleListingCrPageData } from '../../../../article-listing-types'
import styles from './HorizontalArticleCards.module.scss'

export const HorizontalArticleCards: FunctionComponent<Props> = ({
  articles = [],
  className = '',
}) => {
  if (!articles.length) {
    return null
  }

  return (
    <div data-testid="horizontal-article-cards" className={className}>
      {articles.map((article) => (
        <ArticleCard
          key={article.url}
          className={classnames(styles.articleListingHorizontalCard)}
          authorImage={article.authors[0].image?.src}
          authorImageSources={article.authors[0]?.image?.sources ?? []}
          authorName={article.authors[0].name}
          image={(article as FeaturedArticle).image?.src ?? ''}
          imageSources={(article as FeaturedArticle).image?.sources ?? ''}
          imageAlt={(article as FeaturedArticle).image?.alt ?? ''}
          href={article.url}
          orientation="horizontal"
          strapline={article.summary}
          title={article.headline}
        />
      ))}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type FeaturedArticle =
  ArticleListingCrPageData['consumerRightsArticles']['featuredArticles'][number]
type NonFeaturedArticle =
  ArticleListingCrPageData['consumerRightsArticles']['nonFeaturedArticles'][number]

type Props = {
  articles: FeaturedArticle[] | NonFeaturedArticle[]
  className?: string
  hasBottomMargin?: boolean
}
