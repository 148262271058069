import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { assertNever } from '@which/shared'

import {
  useArticleListingAuthorPageQuery,
  useArticleListingCrPageQuery,
  useArticleListingNewsPageQuery,
  useArticleListingReviewsPageQuery,
  useArticleListingTagPageQuery,
} from '../../../generated/frontend'
import type { PageParams } from '../../../routes'
import { usePageProps } from '../../../shared/usePageProps'
import type { ArticleListingTemplateName } from '../article-listing-types'

export const useArticleListingPageQuery = () => {
  const { pathname } = useLocation()
  const { template, context } = usePageProps()

  const { verticalSlug, subVerticalSlug, categorySlug, filterSlug, filterValue } =
    useParams<PageParams>()
  const articleListingTemplate = template as ArticleListingTemplateName

  const useQuery = useMemo(() => {
    switch (articleListingTemplate) {
      case 'CR Subtopic':
        return useArticleListingCrPageQuery
      case 'Reviews Article Listing':
        return useArticleListingReviewsPageQuery
      case 'News Category Listing':
        return useArticleListingNewsPageQuery
      case 'News Author Listing':
        return useArticleListingAuthorPageQuery
      case 'News Tag Listing':
        return useArticleListingTagPageQuery
      default:
        assertNever(articleListingTemplate)
    }
  }, [articleListingTemplate])

  return useQuery({
    variables: {
      subVerticalSlug,
      categorySlug,
      url: pathname,
      verticalSlug,
      filterSlug,
      filterValue,
      context: context || '',
      pageNumber: 1,
    },
  })
}
