import { useLocation, useParams } from 'react-router-dom'
import { assertNever } from '@which/shared'

import type { PageParams } from '../../../routes'
import { usePageProps } from '../../../shared/usePageProps'
import type {
  ArticleListingAuthorPageData,
  ArticleListingCrPageData,
  ArticleListingNewsPageData,
  ArticleListingReviewsPageData,
  ArticleListingTagPageData,
  ArticleListingTemplateName,
  ArticleListingVariantTypes,
} from '../article-listing-types'
import { getCRListingComponents } from '../article-listing-variants/cr-listing'
import { getNewsAuthorListingComponents } from '../article-listing-variants/news-author-listing'
import { getNewsCategoryListingComponents } from '../article-listing-variants/news-category-listing'
import { getNewsTagListingComponents } from '../article-listing-variants/news-tag-listing'
import { getReviewsListingComponents } from '../article-listing-variants/reviews-listing'
import { getComponents } from './base-components'

export const useArticleListingPageComponents = () => {
  const { template, context } = usePageProps()
  const articleListingTemplate = template as ArticleListingTemplateName
  const { pathname } = useLocation()

  const { verticalSlug, filterSlug, filterValue, subVerticalSlug, categorySlug } =
    useParams<PageParams>()

  return {
    getPageComponents(data: ArticleListingVariantTypes) {
      switch (articleListingTemplate) {
        case 'Reviews Article Listing':
          return getComponents(
            data,
            getReviewsListingComponents(data as ArticleListingReviewsPageData)
          )
        case 'CR Subtopic':
          return getComponents(data, getCRListingComponents(data as ArticleListingCrPageData))
        case 'News Category Listing':
          return getComponents(
            data,
            getNewsCategoryListingComponents(data as ArticleListingNewsPageData, {
              subVerticalSlug,
              categorySlug,
              url: pathname,
              context: context || '',
            })
          )
        case 'News Author Listing':
          return getComponents(
            data,
            getNewsAuthorListingComponents(data as ArticleListingAuthorPageData, {
              verticalSlug,
              filterSlug,
              filterValue,
              context: context || '',
            })
          )
        case 'News Tag Listing':
          return getComponents(
            data,
            getNewsTagListingComponents(data as ArticleListingTagPageData, {
              verticalSlug,
              filterSlug,
              filterValue,
              context: context || '',
            })
          )
        default:
          assertNever(articleListingTemplate)
      }
    },
  }
}
