import type { FunctionComponent } from 'react'
import React from 'react'
import { Author as SBAuthor } from '@which/seatbelt'

import type { Author } from '../../../../../generated/frontend'
import styles from './ArticleListingAuthorHeader.module.scss'

export const ArticleListingAuthorHeader: FunctionComponent<Props> = ({ author, count }) => (
  <div className={styles.articleListingAuthorHeader}>
    <SBAuthor
      {...author}
      className={styles.articleListingAuthorHeaderAuthor}
      centered
      authorNameTag="h1"
    />
    {count > 0 && (
      <div className={styles.articleListingAuthorHeaderCount}>
        {`${count} article${count > 1 ? 's' : ''}`}
      </div>
    )}
  </div>
)

///////// IMPLEMENTATION /////////

type Props = {
  author: Author
  count: number
}
